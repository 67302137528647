// Градиент
.text-gradient {
  background             : radial-gradient(100.00% 100.00% at 50.00% -0.00%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 73.05%), radial-gradient(537.14% 76.94% at 30.65% 40.18%, rgba(168, 242, 100, 0.50) 0%, rgba(153, 153, 153, 0.00) 100%), #54B000;
  background-clip        : text;
  -webkit-text-fill-color: transparent;
}

// Градиент
.gradient {
  background-image: linear-gradient(89.97deg, #9BE954 0.02%, #70B92B 17.94%, #54E9A1 36.38%, #54E9A1 55.81%, #54C5E9 66.27%, #034AFF 84.63%);
}

// Шапка документа
.header {
  background-image: linear-gradient(90deg, theme("colors.black.DEFAULT") 0%, rgba(1, 1, 1, 0) 100%);
  transform       : translateY(var(--top));
}

// Меню
.menu {
  &[data-sidebar="close"] {
    @apply invisible opacity-0;
  }

  &[data-sidebar="close"] &__content {
    transform: translateX(-100%);

    @apply invisible opacity-0;
  }

  &[data-sidebar="close"] &__head {
    transform: translateY(-80px);
  }

  &[data-sidebar="close"] &__nav {
    transform: translateX(-220px);

    @apply invisible opacity-0;
  }

  &[data-sidebar="close"] &__footer {
    transform: translateY(-50px);

    @apply invisible opacity-0;
  }

  &[data-sidebar="open"] {
    @apply visible opacity-100;
  }

  &[data-sidebar="open"] &__content {
    transform: translateX(0);

    @apply visible opacity-100;
  }

  &[data-sidebar="open"] &__head {
    transform: translateY(0);
  }

  &[data-sidebar="open"] &__nav {
    transform: translateX(0);

    @apply visible opacity-100;
  }

  &[data-sidebar="open"] &__footer {
    transform: translateY(0);

    @apply visible opacity-100;
  }

  &__content {
    box-shadow: 0 0 0 100vw theme("colors.black.DEFAULT / 50%");
  }
}

// Подарок
.gift {
  left     : calc(98vw - 80px);
  animation: gift 2s linear infinite both;

  @screen lg {
    left: calc(95vw - 160px);
  }

  @screen xl {
    left: calc(98vw - 240px);
  }
}

@keyframes gift {

  0%,
  100% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }
}

// Главный блок
.main {
  &__bg {
    &::after {
      background-image: radial-gradient(66.54% 66.54% at 50.36% 33.46%, rgba(1, 1, 1, 0) 31.47%, theme("colors.black.DEFAULT") 86.56%);

      @apply content-auto absolute inset-0;
    }
  }

  &__pack {
    &::after {
      background-image: linear-gradient(90deg, theme("colors.black.DEFAULT") 0%, rgba(1, 1, 1, 0) 100%), linear-gradient(0deg, theme("colors.black.DEFAULT") 0%, rgba(1, 1, 1, 0.00) 20%);

      @apply content-auto absolute inset-0;
    }
  }
}

// Результат
.result {
  &-slider {
    &--grey {
      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 3.86%, #fff 47.87%, rgba(255, 255, 255, 0) 96.71%);
      transform       : rotate(-1deg);
    }

    &--green {
      background-image: linear-gradient(90.16deg, #9BE954 82.33%, rgba(238, 250, 85, 0) 99.39%);
      transform       : rotate(3deg);
    }
  }

  &-wrapper {
    transition-timing-function: linear !important;
  }
}

// Преимущества
.advantages {
  &__card {
    &[data-advantages="show"] {

      &::after {
        @apply invisible opacity-0;
      }

      @apply z-1 bg-white text-black;

      & .advantages__arrows {
        transform: translateX(0);

        @apply visible opacity-100;
      }
    }

    &::after {
      background-image: linear-gradient(360deg, #010101 0%, rgba(1, 1, 1, 0) 627.23%);

      @apply content-auto absolute -inset-px ease-linear duration-3;
    }
  }

  &__arrows {
    transform: translateX(-50px);
  }
}

// Квиз
.quiz {
  &__bg {
    background-color: #3FA3FF;
  }

  &__radio:checked+&__info {
    background-image: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 73.05%), radial-gradient(59.82% 533.8% at 30.65% 40.18%, rgba(168, 242, 100, 0.5) 0%, rgba(153, 153, 153, 0) 100%);

    @apply bg-primary-light;
  }

  &-slider[data-quiz="stop"] &-next {
    @apply opacity-50 pointer-events-none;
  }

  &-slider[data-quiz-end] &-next {
    @apply hidden;
  }

  &-slider[data-quiz-end] &-submit {
    @apply flex;
  }

  &__radio:checked+&__info &__arrow {
    @apply visible opacity-100;
  }
}

// Портфолио
.portfolio {
  &__category {
    &:active {
      transform: none;
    }

    &.filter-active {
      @apply btn-primary btn-fill btn-gradient border-b-0 border-opacity-0 pointer-events-none;
    }
  }

  &__card.filter-show {
    animation: filtering-show 0.3s ease both 1;
  }
}

@keyframes filtering-show {
  0% {
    transform: translateY(50px);

    @apply opacity-0;
  }

  100% {
    transform: translateY(0);

    @apply opacity-100;
  }
}