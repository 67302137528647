// Переворачивание
.inverted {
  perspective: 600px;

  &[data-inverted] &-before {
    transform: rotateX(0);
  }

  &[data-inverted] &-after {
    transform: rotateX(180deg);

    @apply absolute inset-0;
  }

  &[data-inverted="after"] &-before {
    transform: rotateX(-180deg);
  }

  &[data-inverted="after"] &-after {
    transform: rotateX(0);
  }

  @apply relative;

  &-before,
  &-after {
    transform-style    : preserve-3d;
    backface-visibility: hidden;

    @apply w-full h-full duration-5 ease-in-out;
  }
}